import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MessageModal from "./MessageModal.js";
const axios = require("axios");

export default function OrderLookup(props) {
  const [orderNumber, setOrderNumber] = useState("");
  const [email, setEmail] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [additional, setAdditional] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [formErrors, setFormErrors] = useState({"order number": "", "email": ""});
  const [orderNumberValid, setOrderNumberValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalLinkMessage, setModalLinkMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalShop, setModalShop] = useState("jdsports");
  const [buttonText, setButtonText] = useState("Order lookup");

  useEffect(() => {
    if (location.state && location.state.order) {
      setOrderNumber(location.state.order.orderNumber);
      setEmail(location.state.order.email);
    }
    var additionalTxt = window['jd-returns-config'].additional;
    if (additionalTxt) setAdditional(unescapeCustom(additionalTxt));

    var shop = window['jd-returns-config'].shopName;
    if (shop) {
      switch(shop) {
        case 'deadstock':
          setButtonText("Look up order");
          setModalShop(shop);
          break;
        case 'size':
          setButtonText("Find my order");
          setModalShop(shop);
          break;
        default:
      }
    }
  }, [location, setOrderNumber, setEmail, setAdditional, setButtonText]);

  const updateOrderNumber = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setOrderNumber(value);
    validateField(name, value);
  }, [setOrderNumber]);

  const updateEmail = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmail(value);
    validateField(name, value);
  }, [setEmail]);


  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    // Check if required fields are there
    if (orderNumber && email) {
      setBtnDisable(true);
      setLoading(true);

      try {
        const shopName = window['jd-returns-config'].shopName;
        if (!shopName) {
          alert("NO SHOP");
          setBtnDisable(false);
          setLoading(false);
          return false;
        }

        const params = {
          orderNumber: orderNumber,
          email: email,
          shop: shopName
        };

        const response = await axios.get(`${window['jd-returns-config'].apiUrl}/orders/lookup`, {params});

        if (response.data.success && response.data.order) {
          navigate("/orderDetails", {state: response.data});
        } else {
          setBtnDisable(false);
          setLoading(false);
          // alert(response.data.message);
          setModalTitle("Unable to locate order");
          setModalMessage(response.data.message);
          setModalLinkMessage("Back");
          setModalOpen(true);
        }
      } catch(err) {
        setBtnDisable(false);
        setLoading(false);
        console.log("Error submitting form", err.response.data);
        setModalTitle("There was an error");
        setModalMessage(err.response.data);
        setModalLinkMessage("Back");
        setModalOpen(true);
      }
    } else {
      if (!orderNumberValid) validateField("order number", "");
      if (!emailValid) validateField("email", "");
    }
  }, [navigate, orderNumber, email, setBtnDisable, setLoading, formValid, emailValid, orderNumberValid, setModalTitle, setModalOpen, setModalMessage, setModalLinkMessage]);

  const validateField = useCallback((fieldName, value) => {
    let fieldValidationErrors = formErrors;

    switch(fieldName) {
      case "order number":
        const oVal = value ? true : false;
        setOrderNumberValid(oVal);
        fieldValidationErrors["order number"] = oVal ? "" : " is required";
        break;
      case "email":
        const eVal = value ? true : false;
        setEmailValid(eVal);
        fieldValidationErrors["email"] = eVal ? "" : " is required";
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
    validateForm();
  }, [setOrderNumberValid, setEmailValid, setFormErrors, formErrors]);

  const validateForm = useCallback(() => {
    if (formErrors["order number"] || formErrors["email"]) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [setFormValid, formErrors])

  function unescapeCustom(string) {
    /** Used to map HTML entities to characters. */
    const htmlUnescapes = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#39;': "'"
    }

    /** Used to match HTML entities and HTML characters. */
    const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
    const reHasEscapedHtml = RegExp(reEscapedHtml.source);

      return (string && reHasEscapedHtml.test(string))
        ? string.replace(reEscapedHtml, (entity) => (htmlUnescapes[entity] || "'"))
        : (string || '');
  }

  const closeModalCallback = () => {
    setModalOpen(false);
  }

  return (
    <section className="returns-app__section">
      <form onSubmit={handleSubmit} className="returns-app__section--form">
        <p className="returns-app__section--form-title">Submit a return request</p>
        <div className="returns-app__section--form-inputs">
          <div className="returns-app__section--form-inputs-number">
            <input type="text" name="order number" placeholder="Order Number" value={orderNumber} onChange={updateOrderNumber} required />
          </div>
          <div className="returns-app__section--form-inputs-email">
            <input type="email" name="email" placeholder="Email Address" value={email} onChange={updateEmail} required />
          </div>
        </div>
        <button className="returns-app__section--form-submit" disabled={btnDisable}>
          <span className="returns-app__section--form-submit-text">
            {loading && (
              <i className="is--loading" />
            )}
            {buttonText}
          </span>
        </button>
        <div className="returns-app__section--form-errors">
          {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0) {
              return (
                <p key={i}>{fieldName} {formErrors[fieldName]}</p>
              )        
            } else {
              return ""
            }
          })}
        </div>
        {additional && (
          <p className="returns-app__section--policies" dangerouslySetInnerHTML={{__html: additional}}></p>
        )}
      </form>
      <MessageModal
        parentCallback={closeModalCallback} 
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        modalLinkMessage={modalLinkMessage}
        modalShop={modalShop}
        modalOpen={modalOpen}
      />
    </section>
  );
}