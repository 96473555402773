import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MessageModal from "./MessageModal.js";
import ReCAPTCHA from "react-google-recaptcha";
const axios = require("axios");

export default function OrderDetails(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const orderData = location.state.order || false;
  const orderLineItems = orderData.lineItems || [];
  const [method, setMethod] = useState("default");
  const [reason, setReason] = useState("default");
  const [allSelected, setAllSelected] = useState(false);
  const [comments, setComments] = useState("");
  const [checkedState, setCheckedState] = useState([]);
  const [returnMethods, setReturnMethods] = useState([]);
  const [returnReasons, setReturnReasons] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalLinkMessage, setModalLinkMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalShop, setModalShop] = useState("jdsports");
  const recaptchaRef = useRef(null);

  const [formErrors, setFormErrors] = useState({"order line item": "", "return method": "", "return reason": ""});

  useEffect(() => {
    if (!orderData) {
      return navigate("/");
    }
    // Dynamically create checkbox states for each line item that is eligible for return
    setCheckedState(new Array(orderData.lineItems.length).fill(false));
    var shop = window['jd-returns-config'].shopName;
    if (shop) {
      switch(shop) {
        case 'deadstock':
          setModalShop(shop);
          break;
        case 'size':
          setModalShop(shop);
          break;
        default:
      }
    }

    populateReturnMethods();
    populateReturnReasons();
  }, [orderData, location, navigate, setCheckedState]);

  const populateReturnMethods = useCallback(() => {
    // Get the shop's return methods
    var returnMethods = window['jd-returns-config'].returnMethods;
    if (returnMethods) {
      returnMethods = returnMethods.split("|");
      setReturnMethods(returnMethods);
    }
  }, [setReturnMethods]);

  const populateReturnReasons = useCallback(() => {
    // Get the shop's return reasons
    var returnReasons = window['jd-returns-config'].returnReasons;
    if (returnReasons) {
      returnReasons = returnReasons.split("|");
      setReturnReasons(returnReasons);
    }
  }, [setReturnReasons]);


  // User interactions
  const handleBack = useCallback((e) => {
    navigate("/", {state: location.state});
  }, [navigate, location]);

  const handleSelectAll = useCallback((e) => {
    setAllSelected(!allSelected);

    const updatedCheckedState = checkedState.map((item, index) =>
      !allSelected
    );
    setCheckedState(updatedCheckedState);

  }, [allSelected, setAllSelected, checkedState, setCheckedState]);

  const handleCheckboxChange = useCallback((position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }, [checkedState, setCheckedState]);

  const handleMethodChange = useCallback((e) => {
    setMethod(e.target.value);
  }, [setMethod]);

  const handleReasonChange = useCallback((e) => {
    setReason(e.target.value);
  }, [setReason]);

  const handleCommentsChange = useCallback((e) => {
    setComments(e.target.value);
  }, [setComments]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const captchaToken = recaptchaRef.current.getValue();

    if (validateForm()) {
      setBtnDisable(true);
      setLoading(true);
      if (!captchaToken) {
        setModalTitle("reCAPTCHA required");
        setModalMessage("Plese confirm you are not a robot");
        setModalLinkMessage("Back");
        setModalOpen(true);
        setBtnDisable(false);
        setLoading(false);
        return false;
      }

      const lineItems = checkedState.reduce((selected, item, index) => {
        if (item && orderData.lineItems[index].returnEligible) {
          selected.push(orderData.lineItems[index]);
        }
        return selected;
      }, []);

      const shopName = window['jd-returns-config'].shopName;
      if (!shopName) {
        setModalTitle("Missing shop paramater");
        setModalMessage("Please contact support");
        setModalLinkMessage("Back");
        setModalOpen(true);
        setBtnDisable(false);
        setLoading(false);
        return false;
      }

      const body = {
        shop: shopName,
        orderNumber: orderData.orderNumber,
        email: orderData.email,
        name: orderData.name,
        lineItems: lineItems,
        returnMethod: method,
        returnReason: reason,
        comments: comments,
        captchaToken: captchaToken
      };

      axios
        .post(`${window['jd-returns-config'].apiUrl}/orders/return`, body)
        .then(res => {
          recaptchaRef.current.reset();;
          setModalTitle("Your request has been submitted");
          setModalMessage("Please allow 1-2 business days for a customer service agent to email you confirmation of return/exchange.");
          setModalLinkMessage("Back to returns page");
          setModalOpen(true);
          setBtnDisable(false);
          setLoading(false);
        })
        .catch(err => {
          recaptchaRef.current.reset();
          setBtnDisable(false);
          setLoading(false);
          console.log("Error submitting form", err.response.data);
          setModalTitle("There was an error");
          setModalMessage(err.response.data);
          setModalLinkMessage("Back");
          setModalOpen(true);
        })
    }

  }, [orderData, checkedState, method, reason, comments, setBtnDisable, setLoading, setModalTitle, setModalMessage, setModalOpen, setModalLinkMessage, formErrors, setFormErrors]);

  const validateForm = useCallback(() => {
    let fieldValidationErrors = {};
    let isValid = true;

    if (!checkedState.includes(true)) {
      fieldValidationErrors["order line item"] = " is required";
      isValid = false;
    }

    if (method === "default") {
      fieldValidationErrors["return method"] = " is required";
      isValid = false;
    }

    if (reason === "default") {
      fieldValidationErrors["return reason"] = " is required";
      isValid = false;
    }

    setFormErrors(fieldValidationErrors);
    return isValid;
  }, [checkedState, method, reason, setFormErrors]);

  const closeModalCallback = () => {
    setModalOpen(false);
    if (modalLinkMessage === "Back to returns page") {
      navigate("/");
    }
  }

  function errorClass(error) {
    if (error) {
      return(error.length === 0 ? '' : 'does-have-error');
    }
  }

  const finalSaleText = window['jd-returns-config'].finalSaleText || 'This product is final sale and is excluded from returns';

  return (
    <section className="returns-app__section">
      <button className="returns-app__section--back-button" onClick={handleBack}>Back to order lookup</button>
      <p className="returns-app__section--title">Submit a return request</p>
      <p className="returns-app__section--customer-title"><strong>Order Number:</strong> {orderData.orderName}</p>
      <div className="returns-app__section--customer-split">
        <p className="returns-app__section--customer-title"><strong>Customer Name:</strong> {orderData.name}</p>
        <p className="returns-app__section--customer-title is--right"><strong>Purchase Date:</strong> {orderData.purchaseDate}</p>
      </div>

      <form onSubmit={handleSubmit} className="returns-app__section--form">
        <table className="returns-app__section--form-table">
          <thead>
            <tr>
              <th className="returns-app__section--form-table-select"><input type="checkbox" className={errorClass(formErrors["order line item"])} name="order line item" checked={allSelected} onChange={handleSelectAll}/></th>
              <th className="returns-app__section--form-table-product">Product</th>
              <th className="returns-app__section--form-table-price">Price</th>
            </tr>
          </thead>
          <tbody className="returns-app__section--form-table-body">
            {orderLineItems.map((val, index) => {
              return (
                <tr key={index}>
                  <td className="returns-app__section--form-table-body-select">
                    {val.returnEligible && (
                      <input type="checkbox" 
                        id={"line-item-checkbox-" + index}
                        name="line-item"
                        checked={checkedState[index] || false}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    )}
                  </td>
                  <td className="returns-app__section--form-table-body-product">
                    <div className="line-item__details">
                      <p className="line-item__details--image"><img src={val.imageSrc} /></p>
                      <div className="line-item__details--detail">
                        <p className="line-item__details--detail-title">{val.title}</p>
                        {val.returnEligible?
                          <div>
                            <p className="line-item__details--detail-secondary">{val.variantTitle}</p>
                            <p className="line-item__details--detail-secondary">{val.productCode}</p>
                          </div>
                          :
                          <p className="line-item__details--detail-secondary exclusion">{finalSaleText}</p>
                        }
                      </div>
                    </div>
                    <div className="line-item__details--detail-mobile">
                      {val.returnEligible?
                        <div>
                          <p className="line-item__details--detail-mobile-secondary">{val.variantTitle}</p>
                          <p className="line-item__details--detail-mobile-secondary">{val.productCode}</p>
                        </div>
                        :
                        <p className="line-item__details--detail-mobile-secondary exclusion">{finalSaleText}</p>
                      }
                    </div>
                  </td>
                  <td className="returns-app__section--form-table-body-price">
                    ${val.price}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot className="returns-app__section--form-table-footer-mobile">
            <tr>
              <td className="total-label" colSpan="5"><strong>Total</strong>&nbsp; &nbsp;${orderData.subtotalPrice} {orderData.currency}</td>
            </tr>
          </tfoot>
          <tfoot className="returns-app__section--form-table-footer-desktop">
            <tr>
              <td></td>
              <td className="total-label"><strong>Total</strong></td>
              <td className="total-price">${orderData.subtotalPrice} {orderData.currency}</td>
            </tr>
          </tfoot>
        </table>
        <p className="returns-app__section--form-details-title">Return Details</p>
        <div className="returns-app__section--form-reasons">
          <select className={errorClass(formErrors["return method"])} name="return method" defaultValue={method} onChange={handleMethodChange} required>
            <option value="default" disabled>Return method</option>
            {returnMethods.map((m) => {
              return (
                <option value={m} key={m}>{m}</option>
              )
            })}
          </select>
          <select className={errorClass(formErrors["return reason"])} name="return reason" defaultValue={reason}  onChange={handleReasonChange}>
            <option value="default" disabled>Return reason</option>
            {returnReasons.map((r) => {
              return (
                <option value={r} key={r}>{r}</option>
              )
            })}
          </select>
        </div>
        <textarea name="customer_comments"
          className="returns-app__section--form-comments"
          placeholder="Customer comments (optional)" 
          value={comments}
          onChange={handleCommentsChange} 
        />
        <div className="returns-app__section--form-recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfIR6AeAAAAANWMvCiMBhBCF0Guh5mamfaPr-mE"
          />
        </div>
        <button className="returns-app__section--form-submit" disabled={btnDisable}>
          <span className="returns-app__section--form-submit-text">
            {loading && (
              <i className="is--loading" />
            )}
            Submit return request
          </span>
        </button>
        <div className="returns-app__section--form-errors">
          {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0) {
              return (
                <p key={i}>{fieldName} {formErrors[fieldName]}</p>
              )        
            } else {
              return ""
            }
          })}
        </div>
      </form>
      <MessageModal
        parentCallback={closeModalCallback} 
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        modalLinkMessage={modalLinkMessage}
        modalShop={modalShop}
        modalOpen={modalOpen}
      />
    </section>
  );
}