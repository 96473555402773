import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ContactFormApp from './ContactFormApp';
import reportWebVitals from './reportWebVitals';

let readyFired = false;
let readyEventHandlersInstalled = false;

(function () {
  // if document already ready to go, schedule the ready function to run
  if (document.readyState === 'complete') {
    setTimeout(ready, 1);
  } else if (!readyEventHandlersInstalled) {
    // otherwise if we don't have event handlers installed, install them
    if (document.addEventListener) {
      // first choice is DOMContentLoaded event
      document.addEventListener('DOMContentLoaded', ready, false);
      // backup is window load event
      window.addEventListener('load', ready, false);
    } else {
      // must be IE
      document.attachEvent('onreadystatechange', () => document.readyState === 'complete' && ready());
      window.attachEvent('onload', ready);
    }
    readyEventHandlersInstalled = true;
  }
})();

// call this when the document is ready
// this function protects itself against being called more than once
function ready() {
  if (!readyFired) {
    readyFired = true;
    renderDOM();
  }
}

function renderDOM() {
  const returnElement = document.querySelector('[data-jd-returns-app]');

  if (returnElement != null) {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      returnElement
    );
  }

  const contactForm = document.querySelector('[data-jd-returns-app-contact]');

  if (contactForm != null) {
    ReactDOM.render(
      <React.StrictMode>
        <ContactFormApp />
      </React.StrictMode>,
      contactForm
    );
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
