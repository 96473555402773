import React, { } from "react";
import Modal from "react-modal";
import "./Modal.scss";

export default function MessageModal(props) {
  Modal.setAppElement('body');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    props.parentCallback()
  }

  return (
    <div>
      <Modal
        isOpen={props.modalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel=""
      >
        <div className={"returns-app__modal " + props.modalShop}>
          <div className="returns-app__modal--close"><span onClick={closeModal}></span></div>
          <div className="returns-app__modal--inner">
            <p className="returns-app__modal--title">{props.modalTitle}</p>
            <p className="returns-app__modal--message">{props.modalMessage}</p>
            <button className="returns-app__modal--button" onClick={closeModal}>{props.modalLinkMessage}</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}