import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MessageModal from "./MessageModal.js";
import ReCAPTCHA from "react-google-recaptcha";
const axios = require("axios");

export default function ContactForm(props) {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [issue, setIssue] = useState("default");
  const [issues, setIssues] = useState([]);
  const [formErrors, setFormErrors] = useState({"order number": "", "email": ""});
  const [emailValid, setEmailValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalLinkMessage, setModalLinkMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalShop, setModalShop] = useState("jdsports");
  const recaptchaRef = useRef(null);

  useEffect(() => {
    setIssues([
      { k: "contact_shipped", v: "When will my order be shipped?" },
      { k: "contact_pickup", v: "When will my order be ready for pick up?" },
      { k: "contact_sizing", v: "Is this men's or women's sizing?" },
      { k: "contact_promo_code", v: "Why is my promo code not working?" },
      { k: "contact_stock", v: "Do you have an item in stock?" },
      { k: "contact_release", v: "Question about release product" },
      { k: "contact_refund", v: "How long will it take for you to process my refund?" },
      { k: "contact_other", v: "Other / issue not listed" }
    ]);

    var shop = window['jd-returns-config'].shopName;
    if (shop) {
      switch(shop) {
        case 'deadstock':
          setModalShop(shop);
          break;
        case 'size':
          setModalShop(shop);
          break;
        default:
      }
    }
  }, [setIssues]);

  const validateFormFields = useCallback(() => {
    if (formErrors["Email"]) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [setFormValid, formErrors])

  const validateField = useCallback((fieldName, value) => {
    let fieldValidationErrors = formErrors;

    switch(fieldName) {
      case "email":
        const eVal = value ? true : false;
        setEmailValid(eVal);
        fieldValidationErrors["email"] = eVal ? "" : " is required";
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
    validateFormFields();
  }, [setEmailValid, setFormErrors, validateFormFields, formErrors]);

  const updateFirstName = useCallback((e) => {
    const value = e.target.value;
    setFirstName(value);
  }, [setFirstName]);

  const updateLastName = useCallback((e) => {
    const value = e.target.value;
    setLastName(value);
  }, [setLastName]);

  const updateEmail = useCallback((e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEmail(value);
    validateField(name, value);
  }, [setEmail, validateField]);


  const handleIssueChange = useCallback((e) => {
    setIssue(e.target.value);
  }, [setIssue]);

  const handleMessageChange = useCallback((e) => {
    setMessage(e.target.value);
  }, [setMessage]);

  const validateForm = useCallback(() => {
    let fieldValidationErrors = {};
    let isValid = true;

    if (!firstName) {
      fieldValidationErrors["First name"] = " is required";
      isValid = false;
    }

    if (!lastName) {
      fieldValidationErrors["Last name"] = " is required";
      isValid = false;
    }

    if (issue === "default") {
      fieldValidationErrors["Issue"] = " is required";
      isValid = false;
    }

    if (!email) {
      fieldValidationErrors["Email"] = " is required";
      isValid = false;
    }

    if (!message) {
      fieldValidationErrors["Message"] = " is required";
      isValid = false;
    }

    setFormErrors(fieldValidationErrors);
    return isValid;
  }, [firstName, lastName, issue, email, message, setFormErrors]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const captchaToken = recaptchaRef.current.getValue();

    if (validateForm()) {
      setBtnDisable(true);
      setLoading(true);
      if (!captchaToken) {
        setModalTitle("reCAPTCHA required");
        setModalMessage("Plese confirm you are not a robot");
        setModalLinkMessage("Back");
        setModalOpen(true);
        setBtnDisable(false);
        setLoading(false);
        return false;
      }

      const shopName = window['jd-returns-config'].shopName;
      if (!shopName) {
        setModalTitle("Missing shop paramater");
        setModalMessage("Please contact support");
        setModalLinkMessage("Back");
        setModalOpen(true);
        setBtnDisable(false);
        setLoading(false);
        return false;
      }

      const body = {
        shop: shopName,
        firstName: firstName,
        lastName: lastName,
        email: email,
        issueType: issue,
        issue: issues.find(x => x.k === issue).v,
        message: message,
        captchaToken: captchaToken
      };

      axios
        .post(`${window['jd-returns-config'].apiUrl}/help/contact`, body)
        .then(res => {
          recaptchaRef.current.reset();;
          setModalTitle("Your request has been submitted");
          setModalMessage("Thank you! Your request has been submitted to our customer support team. We'll get back to you as soon as possible!");
          setModalLinkMessage("Close");
          setModalOpen(true);
          setBtnDisable(false);
          setLoading(false);
          resetForm();
        })
        .catch(err => {
          recaptchaRef.current.reset();
          setBtnDisable(false);
          setLoading(false);
          console.log("Error submitting form", err.response.data);
          setModalTitle("There was an error");
          setModalMessage(err.response.data);
          setModalLinkMessage("Back");
          setModalOpen(true);
        })
    }

  }, [firstName, lastName, email, issue, issues, message, setBtnDisable, setLoading, setModalTitle, setModalMessage, setModalOpen, setModalLinkMessage, validateForm]);

  function resetForm() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setIssue("");
    setMessage("");
  }
  const closeModalCallback = () => {
    setModalOpen(false);
    if (modalLinkMessage === "Close") {
      navigate("/");
    }
  }

  function errorClass(error) {
    if (error) {
      return(error.length === 0 ? '' : 'does-have-error');
    }
  }

  return (
    <section className="returns-app__section">
      <form onSubmit={handleSubmit} className="returns-app__section--form">
        <div className="returns-app__section--form-inputs">
          <div className="returns-app__section--form-inputs-text">
            <input type="text" name="first name" placeholder="First Name" className={errorClass(formErrors["First name"])} value={firstName} onChange={updateFirstName} required />
          </div>
          <div className="returns-app__section--form-inputs-text">
            <input type="text" name="last name" placeholder="Last Name" className={errorClass(formErrors["Last name"])} value={lastName} onChange={updateLastName} required />
          </div>
        </div>
        <div className="returns-app__section--form-inputs full">
          <div className="returns-app__section--form-inputs-email">
            <input type="email" name="email" placeholder="Email Address" className={errorClass(formErrors["Email"])} value={email} onChange={updateEmail} required />
          </div>
        </div>
        <div className="returns-app__section--form-reasons full">
          <select className={errorClass(formErrors["Issue"])} name="contact issue" defaultValue={issue} onChange={handleIssueChange} required>
            <option value="default" disabled>Issue</option>
            {issues.map((i) => {
              return (
                <option value={i.k} key={i.k}>{i.v}</option>
              )
            })}
          </select>
        </div>
        <textarea name="customer_comments"
          className={'returns-app__section--form-comments ' + errorClass(formErrors["Message"])}
          placeholder="Message" 
          value={message}
          onChange={handleMessageChange} 
          required
        />
        <div className="returns-app__section--form-recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdeevcqAAAAAIksRve0lLkMWwc9cpPK-zwqPHPu"
          />
        </div>
        <button className="returns-app__section--form-submit" disabled={btnDisable}>
          <span className="returns-app__section--form-submit-text">
            {loading && (
              <i className="is--loading" />
            )}
            Submit
          </span>
        </button>
        <div className="returns-app__section--form-errors">
          {Object.keys(formErrors).map((fieldName, i) => {
            if(formErrors[fieldName].length > 0) {
              return (
                <p key={i}>{fieldName} {formErrors[fieldName]}</p>
              )        
            } else {
              return ""
            }
          })}
        </div>
      </form>
      <MessageModal
        parentCallback={closeModalCallback} 
        modalTitle={modalTitle}
        modalMessage={modalMessage}
        modalLinkMessage={modalLinkMessage}
        modalShop={modalShop}
        modalOpen={modalOpen}
      />
    </section>
  );
}