import React from "react";
import {
  MemoryRouter,
  Routes,
  Route
} from "react-router-dom";
import './jdsports.scss';
import './deadstock.scss';
import './size.scss';
import ContactForm from "./ContactForm";

export default function App() {
  return (
    <MemoryRouter>
        <div>
          <Routes>
            <Route exact path="/" element={<ContactForm />} />
          </Routes>
        </div>
    </MemoryRouter>
  );
}