import React from "react";
import {
  MemoryRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './jdsports.scss';
import './deadstock.scss';
import './size.scss';
import OrderLookup from "./OrderLookup";
import OrderDetails from "./OrderDetails";

export default function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/orderDetails" element={<OrderDetails />} />
          <Route path="/" element={<OrderLookup />} />
        </Routes>
      </div>
    </Router>
  );
}